.about {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
  height: 100%;
  width: 100%;
  transition: all 5s;

  &__heading {
    align-items: center;
    display: flex;
    grid-column: 2 / 5;
    grid-row: 2;
    font-family: "Arvo", serif;
    font-size: 5rem;
    justify-content: center;
    text-shadow: 1px 1px 2px $color-dark;

    @include respond(tab-land) {
      grid-column: 2 / 5;
    }

    @include respond(tab-port) {
      grid-column: 1 / 4;
    }

    @include respond(phone) {
      font-size: 3.5rem;
      grid-column: 2 / 7;
      grid-row: 2;
      text-align: center;
    }
  }

  &__heading:hover {
    animation: nameFlip 5s 0.5s;
  }

  &__text {
    grid-column: 2 / 7;
    grid-row: 3 / 6;
    font-family: "Arvo", serif;
    font-size: 1.6rem;
    line-height: 3rem;
    margin-top: 1.5rem;

    @include respond(tab-land) {
      font-size: 2rem;
      line-height: 3rem;
      grid-column: 2 / 7;
    }

    @include respond(tab-port) {
      font-size: 2rem;
      line-height: 3rem;
    }

    @include respond(phone) {
      font-size: 1.5rem;
      grid-column: 2 / 7;
      grid-row: 4;
      line-height: 2rem;
    }
  }

  &__img {
    background: url("/Photos/About/nate.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 80%;
    box-shadow: 5px 5px 3px $color-dark;
    grid-column: 5 / 7;
    grid-row: 2;
    height: 35rem;
    width: 35rem;

    @include respond(tab-land) {
      grid-column: 5 / 7;
    }

    @include respond(tab-port) {
      grid-column: 5 / 7;
    }

    @include respond(phone) {
      grid-column: 4;
      grid-row: 3;
      height: 20rem;
      width: 20rem;
    }
  }
  &__img:hover {
    animation: imgRotate 5s;
  }

  @media only screen and (max-width: 56.25em), only screen and (hover: none) {
    &__heading:hover {
      animation: none;
    }
    &__img:hover {
      animation: none;
    }
  }
}
