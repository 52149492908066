.btn {
  background-color: $color-off-white;
  border: none;
  border-radius: 3px;
  color: $color-medium-blue;
  font-size: 1.6rem;
  padding: 0.5rem;
  text-align: center;
  transition: all 1s;
}

.btn:hover {
  background-color: $color-medium-blue;
  border-radius: 3px;
  box-shadow: 1.5rem 1rem $color-off-white;
  color: $color-off-white;
  transform: scale(1.2);
}

@media only screen and (max-width: 56.25em), only screen and (hover: none) {
  .btn:hover {
    background-color: $color-off-white;
    box-shadow: none;
    color: $color-medium-blue;
    transform: scale(1);
  }
}
