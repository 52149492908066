.container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 84vh;
  width: 100vw;
}

.scene {
  width: 100vw;
  height: 84vh;
  overflow: hidden;
  perspective: 100vw;
}

.cube {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(-1000px);
  transition: transform 1s ease-in-out;
  transform-origin: center;

  &__face {
    color: $color-off-white;
    position: absolute;
    width: 100%;
    height: 100%;

    &--home {
      transform: rotateY(0deg) translateZ(1000px);
    }

    &--personalProjects {
      background-color: $color-light-grey;
      transform: rotateY(90deg) translateZ(1000px);
    }

    &--about {
      background-color: $color-light-grey;
      transform: rotateY(180deg) translateZ(1000px);
    }

    &--contact {
      background-color: $color-light-grey;
      transform: rotateY(-90deg) translateZ(1000px);
    }

    &--skills {
      background-color: $color-light-grey;
      transform: rotateX(90deg) translateZ(1000px);
    }

    &--professionalProjects {
      background-color: $color-light-grey;
      transform: rotateX(-90deg) translateZ(1000px);
    }
  }
}

.show-home {
  transform: translateZ(-1000px) rotateY(0deg);
}
.show-skills {
  transform: translateZ(-1000px) rotateX(-90deg);
}
.show-personalProjects {
  transform: translateZ(-1000px) rotateY(-90deg);
}
.show-about {
  transform: translateZ(-1000px) rotateY(-180deg);
}
.show-contact {
  transform: translateZ(-1000px) rotateY(90deg);
}
.show-prop {
  transform: translateZ(-1000px) rotateX(-90deg);
}
.show-professionalProjects {
  transform: translateZ(-1000px) rotateX(90deg);
}
