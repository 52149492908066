.home {
  background: url("/Photos/Home/Marina_Bay_Morning_2.JPG"), $color-light-blue;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;

  &__grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    height: 100%;
    width: 100%;
  }

  &__heading {
    align-items: center;
    background-color: rgba($color-dark, 0.5);
    border-radius: 3px;
    display: flex;
    font-size: 6rem;
    grid-column: 3;
    grid-row: 3;
    justify-content: center;
    text-shadow: 1px 1px 2px $color-dark;
  }

  &__text {
    align-items: center;
    background-color: rgba($color-dark, 0.5);
    border-radius: 3px;
    display: flex;
    font-size: 3rem;
    grid-column: 3 / 5;
    grid-row: 4;
    justify-content: center;
    text-shadow: 1px 1px 2px $color-dark;
  }
}
