.nav {
  background: $color-new-blue;
  display: flex;
  height: 10vh;
  justify-content: space-between;
  width: 100vw;

  &__logo {
    background: url("/Photos/logo.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $color-off-white;
    border-radius: 80%;
    box-shadow: 5px 3px 3px $color-dark;
    margin: auto 0;
    margin-left: 5px;
    cursor: pointer;
    height: 7rem;
    width: 7rem;
    transition: all 0.5s;

    @include respond(phone) {
      height: 4rem;
      margin-left: 0;
      width: 4rem;
    }
  }

  &__logo:hover {
    transform: scale(1.2);
  }

  @media only screen and (max-width: 56.25em), only screen and (hover: none) {
    &__logo:hover {
      transform: scale(1);
    }

    &__btn:hover {
      box-shadow: none;
      transform: scale(1);
    }
  }

  &__list {
    align-items: center;
    display: flex;
    list-style-type: none;
    justify-content: space-around;
    padding: 0;
    width: 100%;
  }

  &__btn {
    box-shadow: 5px 3px 3px $color-dark;
    cursor: pointer;
    font-family: "Roboto Slab", serif;

    @include respond(tab-land) {
      font-size: 2.5rem;
    }

    @include respond(phone) {
      font-size: 1rem;
    }
  }
}
