.skills {
  display: grid;
  grid-template-rows: repeat(12, 1fr);
  grid-template-columns: repeat(11, 1fr);
  height: 100%;
  width: 100%;

  @include respond(phone) {
    grid-template-columns: repeat((4, 1fr));
  }

  &__list1 {
    list-style: none;
    grid-row: 2 / 8;
    grid-column: 3;
    text-align: center;

    @include respond(phone) {
      grid-column: 1;
    }
  }
  &__list2 {
    list-style: none;
    grid-row: 2 / 8;
    grid-column: 5;
    text-align: center;

    @include respond(phone) {
      grid-column: 2;
    }
  }
  &__list3 {
    list-style: none;
    grid-row: 2 / 8;
    grid-column: 7;
    text-align: center;

    @include respond(phone) {
      grid-column: 3;
    }
  }

  &__list4 {
    list-style: none;
    grid-row: 2 / 8;
    grid-column: 9;
    text-align: center;

    @include respond(phone) {
      grid-column: 4;
    }
  }

  &__item {
    font-size: 2.5rem;
    margin: 4rem 0px;

    @include respond(tab-land) {
      font-size: 3rem;
    }

    @include respond(tab-port) {
      font-size: 3rem;
    }

    @include respond(phone) {
      font-size: 1.5rem;
    }
  }

  &__icon {
    border-radius: 80%;
    margin-right: 4px;
    font-size: 5rem;

    @include respond(tab-land) {
      font-size: 9rem;
    }

    @include respond(tab-port) {
      font-size: 8rem;
    }

    @include respond(phone) {
      font-size: 5rem;
    }
  }
}

.html {
  background-color: $color-eggshell;
  color: #f16528;
}

.css {
  background-color: $color-eggshell;
  color: #2965f1;
}

.git {
  background-color: $color-eggshell;
  color: #ea4f31;
}

.boot {
  background-color: $color-eggshell;
  color: #563d7c;
}

.sass {
  background-color: $color-eggshell;
  color: #c86495;
}

.npm {
  background-color: $color-eggshell;
  color: #cb3838;
}

.js {
  background-color: $color-eggshell;
  color: #f7df1d;
}

.query {
  background-color: $color-eggshell;
  color: #0765a6;
}

.mongo {
  background-color: $color-eggshell;
  color: #419642;
}

.react {
  background-color: $color-eggshell;
  color: #5ed4f4;
}

.node {
  background-color: $color-eggshell;
  color: #75a371;
}

.ruby {
  background-color: $color-eggshell;
  color: #ba1901;
}

.py {
  background-color: $color-eggshell;
  color: #f4dd5a;
}

.post {
  background-color: $color-eggshell;
  color: #31648d;
}
