.loading {
  align-items: center;
  background-color: $color-dark-blue;
  display: flex;
  font-family: sans-serif;
  height: 100vh;
  justify-content: center;
  margin: 0;

  &__list {
    display: flex;
    margin: 0;
    padding: 0;
  }
  &__item {
    animation: loading 3s linear infinite;
    font-size: 80px;
    letter-spacing: 20px;
    list-style-type: none;
  }
}

.load1 {
  animation-delay: 0s;
}
.load2 {
  animation-delay: 0.2s;
}
.load3 {
  animation-delay: 0.4s;
}
.load4 {
  animation-delay: 0.6s;
}
.load5 {
  animation-delay: 0.8s;
}
.load6 {
  animation-delay: 1s;
}
.load7 {
  animation-delay: 1.2s;
}
