*,
*::after,
*::before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;

  // width < 1200px
  @include respond(tab-land) {
    font-size: 56.25%; // 1 rem = 9px, 9/16 = 50%
  }

  // width < 900px
  @include respond(tab-port) {
    font-size: 50%; // 1 rem = 8px, 8/16 = 50%
  }

  //width > 1200px
  @include respond(big-desk) {
    font-size: 75%; // 1 rem = 12px, 12/16
  }
}

body {
  background: $color-dark-grey;
  box-sizing: border-box;
}

.app {
  height: 100vh;
  width: 100vw;
}
