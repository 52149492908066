.footer {
  align-items: center;
  background: $color-black;
  display: flex;
  height: fit-content;
  justify-content: space-around;
  width: 100%;

  &__icon {
    height: 5rem;
    width: 5rem;
    margin-right: 0.5rem;
  }

  &__text {
    color: $color-off-white;
    text-decoration: none;
    transition: all 0.5s;
  }

  &__text:hover {
    border-radius: 3px;
    color: $color-light-blue;
  }
}
