.contact {
  display: grid;
  grid-template-rows: repeat(8, 1fr);
  grid-template-columns: repeat(6, 1fr);
  height: 100%;
  width: 100%;

  &__heading {
    color: $color-off-white;
    font-size: 6rem;
    grid-row: 2;
    grid-column: 2 / 6;
    text-align: center;
    text-shadow: 5px 5px 5px $color-dark;
  }

  &__error {
    align-items: center;
    border-radius: 3px;
    color: $color-off-white;
    display: flex;
    font-size: 6rem;
    grid-row: 2;
    grid-column: 2 / 6;
    justify-content: center;
    text-align: center;
    text-shadow: 5px 5px 5px $color-dark;
  }

  &__form {
    grid-row: 3 / 8;
    grid-column: 2 / 6;

    @include respond(phone) {
      grid-column: 1 / 7;
      padding: 2rem;
    }
  }
}
