$color-dark-blue: #31708e;
$color-medium-blue: #5085a5;
$color-light-blue: #8fc1e3;
$color-off-white: #f7f9fb;
$color-forrest: #687864;
$color-dark: #2a2a2e;
$color-eggshell: #fbf9ed;
$color-black: #000;

$color-dark-grey: #303841;
$color-light-grey: #47555e;
$color-new-blue: #7aa5d2;
