.card {
  background: $color-eggshell;
  border-radius: 3px;
  color: $color-dark;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-template-columns: repeat(6, 1fr);
  height: 100%;
  width: 100%;
  transition: all 0.3s ease-in-out;

  &__text {
    grid-row: 5;
    grid-column: 2 / 6;
    font-family: "Arvo", serif;
    font-size: 1.2rem;
    line-height: 1.2;
    padding-top: 2px;

    @include respond(phone) {
      font-size: 1rem;
    }
  }

  &__link {
    color: $color-dark-blue;
    font-size: 1.2rem;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px $color-light-blue;
    grid-row: 6;
    grid-column: 2 / 3;
    margin-top: 2px;
  }

  &__repo {
    color: $color-dark-blue;
    font-size: 1.2rem;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px $color-light-blue;
    grid-row: 6;
    grid-column: 4 / 5;
    margin-top: 2px;
  }

  &__link:hover,
  &__repo:hover {
    color: $color-light-blue;
  }

  &__img {
    grid-row: 2 / 5;
    grid-column: 2 / 6;
    height: 100%;
    width: 100%;
  }
}

.card:hover {
  transform: scale(1.5);
  box-shadow: 20px 16px 10px $color-dark;
}

.card1 {
  grid-row: 2 / 5;
  grid-column: 2 / 4;
}

.card2 {
  grid-row: 2 / 5;
  grid-column: 4 / 7;
}

.card3 {
  grid-row: 5 / 8;
  grid-column: 2 / 4;
}

.card4 {
  grid-row: 5 / 8;
  grid-column: 4 / 7;
}
