.form {
  background-color: $color-dark-grey;
  box-shadow: 5px 5px 3px $color-dark;
  color: $color-off-white;
  border-radius: 3px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  gap: 5px;
  height: 100%;
  width: 100%;

  &__name {
    align-items: center;
    display: flex;
    font-size: 2rem;
    justify-content: center;
    grid-row: 2;
    grid-column: 2;

    @include respond(phone) {
      grid-row: 1;
      grid-column: 3 / 4;
      margin-left: 5rem;
    }

    &__input {
      background-color: $color-off-white;
      border-radius: 3px;
      grid-row: 2;
      grid-column: 3 / 6;
      text-align: center;

      @include respond(phone) {
        grid-column: 2 / 6;
      }
    }
  }

  &__email {
    align-items: center;
    display: flex;
    font-size: 2rem;
    justify-content: center;
    grid-row: 3;
    grid-column: 2;

    @include respond(phone) {
      grid-column: 3 / 4;
      margin-left: 5rem;
    }

    &__input {
      background-color: $color-off-white;
      border-radius: 3px;
      grid-row: 3;
      grid-column: 3 / 6;
      text-align: center;

      @include respond(phone) {
        grid-column: 2 / 6;
        grid-row: 4;
      }
    }
  }

  &__message {
    align-items: center;
    display: flex;
    font-size: 2rem;
    justify-content: center;
    grid-row: 4;
    grid-column: 2;

    @include respond(phone) {
      grid-column: 3 / 4;
      grid-row: 5;
      margin-left: 5rem;
    }

    &__input {
      background-color: $color-off-white;
      border-radius: 3px;
      grid-row: 4;
      grid-column: 3 / 6;
      text-align: center;

      @include respond(phone) {
        grid-column: 2 / 6;
        grid-row: 6;
      }
    }
  }

  &__btn {
    grid-row: 5;
    grid-column: 5;
    margin-top: 1rem;

    @include respond(tab-land) {
      grid-row: 7;
      grid-column: 2 / 6;
      margin-top: 0;
      margin-bottom: 1rem;
    }

    @include respond(phone) {
    }
  }
}
