.personalCardImg1 {
  background: url("/Photos/Projects/Personal/to-do-list.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.personalCardImg2 {
  background: url("/Photos/Projects/Personal/workout.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.personalCardImg3 {
  background: url("/Photos/Projects/Personal/budgeter.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.personalCardImg4 {
  background: url("/Photos/Projects/Personal/ACNH.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.personal {
  font-size: 4rem;
  grid-row: 1;
  grid-column: 4;
  margin-top: 2rem;

  @include respond(phone) {
    font-size: 2rem;
  }
}
