@keyframes imgRotate {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes nameFlip {
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(360deg) scale(1.2);
  }
  100% {
    transform: rotateX(0deg);
  }
}

@keyframes loading {
  0% {
    color: $color-medium-blue;
    letter-spacing: 20px;
    opacity: 0;
    transform: translateY(-50px);
  }
  10% {
    opacity: 1;
  }
  50% {
    letter-spacing: -20px;
    opacity: 1;
  }
  100% {
    color: $color-black;
    letter-spacing: 20px;
    opacity: 0;
    transform: translateY(50px);
  }
}
