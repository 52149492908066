.professional {
  font-size: 4rem;
  grid-row: 1;
  grid-column: 4;
  margin-top: 2rem;
  @include respond(phone) {
    font-size: 2rem;
  }
}

.professionalCardImg1 {
  background: url("/Photos/Projects/placeholder.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.professionalCardImg2 {
  background: url("/Photos/Projects/Professional/greatExpectations.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.professionalCardImg3 {
  background: url("/Photos/Projects/Professional/peaceOfMind.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
